/* You can add global styles to this file, and also import other style files */

/* === Plugin styles === */


@import '~react-datepicker/dist/react-datepicker.css';

@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "../assets/styles/variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "../assets/styles/fonts";
@import "../assets/styles/functions";

/* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";
@import "~font-awesome/scss/font-awesome";


/* === Template mixins === */
@import "../assets/styles/mixins/animation";
@import "../assets/styles/mixins/buttons";
@import "../assets/styles/mixins/badges";
@import "../assets/styles/mixins/misc";
@import "../assets/styles/mixins/color-functions";
@import "../assets/styles/mixins/cards";
@import "../assets/styles/mixins/blockqoute";


/* === Core Styles === */
@import "../assets/styles/background";
@import "../assets/styles/typography";
@import "../assets/styles/reset";
@import "../assets/styles/responsive";
@import "../assets/styles/misc";
@import "../assets/styles/utilities";
@import "../assets/styles/demo";
@import "../assets/styles/spinner";
@import "../assets/styles/dashboard";

/* === Components === */

@import "../assets/styles/components/forms";
@import "../assets/styles/components/checkbox-radio";
@import "../assets/styles/components/icons";
@import "../assets/styles/components/tables";
@import "../assets/styles/components/buttons";
@import "../assets/styles/components/badges";
@import "../assets/styles/components/cards";
@import "../assets/styles/components/dropdowns";
@import "../assets/styles/components/todo-list";
@import "../assets/styles/components/spinner";
@import "../assets/styles/components/bootstrap-progress";
@import "../assets/styles/components/preview";

@import "../assets/styles/components/landing-screens/auth";

/* === Plugin Overrides === */

/* === Layout === */
@import "../assets/styles/navbar";
@import "../assets/styles/sidebar";
@import "../assets/styles/footer";
@import "../assets/styles/layout";


::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.btn-google {
  color: #545454;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 1px #ddd
}
.btn-google:hover {
  color: #545454;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 1px #ddd
}

.or-container {
  align-items: center;
  color: #ccc;
  display: flex;
  margin: 10px 0 0 0
}

.line-separator {
  background-color: #ccc;
  flex-grow: 5;
  height: 1px
}

.or-label {
  flex-grow: 1;
  margin: 0 15px;
  text-align: center
}

.select-input-invalid {
  border-color: #dc3545 !important ;
  border: 1  !important;
  padding-right: calc(1.5em + .75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
  background-repeat: no-repeat !important;
  background-position: right calc(.375em + .1875rem) center !important;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem) !important;
}

.react-datepicker-wrapper {
  width:  100%;
}

.pass-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 14px;
  i {
    position: absolute;
    top: 25%;
    right: 2%;
    &:hover {
    color: #545454;
    cursor: pointer;
  }
  }

}

p.cst-text-wrap {
    word-wrap: break-word;
}